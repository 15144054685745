import { SET_CURRENT_PAGE } from "./actions";

export const initialState = {
  currentPage: {
    id: "",
    name: "",
    link: "",
  },
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_PAGE:
      return { ...state, ...{ currentPage: payload } };
    default:
      return state;
  }
};
