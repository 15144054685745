import { createContext, useReducer } from "react";
import { initialState, reducer } from "../../store/language";

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LanguageContext.Provider value={{ state, dispatch }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };
