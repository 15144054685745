import { createContext, useReducer } from "react";
import { initialState, reducer } from "../../store/featuredArticles";

const FeaturedArticleContext = createContext();

const FeaturedArticleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <FeaturedArticleContext.Provider value={{ state, dispatch }}>
      {children}
    </FeaturedArticleContext.Provider>
  );
};

export { FeaturedArticleProvider, FeaturedArticleContext };
