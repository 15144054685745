import { CurrentPageProvider, LanguageProvider } from "../context";

const RootElement = ({ children }) => {
  return (
    <LanguageProvider>
      <CurrentPageProvider>{children}</CurrentPageProvider>
    </LanguageProvider>
  );
};

export default RootElement;
