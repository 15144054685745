import { SET_LANGUAGE } from "./actions";

export const initialState = {
  language: {
    code: "en",
    langDefault: true,
    hrefLang: "en-US",
    name: "English",
    localName: "English",
    langDir: "ltr",
    dateFormat: "MM/DD/YYYY",
  },
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LANGUAGE:
      return { ...state, ...{ language: payload } };
    default:
      return state;
  }
};
