import { createContext, useReducer } from "react";
import { initialState, reducer } from "../../store/currentPage";

const CurrentPageContext = createContext();

const CurrentPageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <CurrentPageContext.Provider value={{ state, dispatch }}>
      {children}
    </CurrentPageContext.Provider>
  );
};

export { CurrentPageProvider, CurrentPageContext };
