exports.components = {
  "component---1318378655-cal-size-defect-and-treated-with-and-without-alendronate-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-to-present-the-results-of-a-pre-clinical-study-evaluating-ossure-in-canines-with-a-critical-size-defect-and-treated-with-and-without-alendronate.mdx" /* webpackChunkName: "component---1318378655-cal-size-defect-and-treated-with-and-without-alendronate-mdx" */),
  "component---1546937833-n-symposium-on-ultrasonic-characterization-of-bone-esucb-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/prof-klaus-engelke-presents-qct-methodology-during-the-21st-international-bone-densitometry-workshop-ibdw-and-7th-european-symposium-on-ultrasonic-characterization-of-bone-esucb.mdx" /* webpackChunkName: "component---1546937833-n-symposium-on-ultrasonic-characterization-of-bone-esucb-mdx" */),
  "component---2841818740-n-symposium-on-ultrasonic-characterization-of-bone-esucb-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/prof-klaus-engelke-presents-qct-methodology-during-the-21st-international-bone-densitometry-workshop-ibdw-and-7th-european-symposium-on-ultrasonic-characterization-of-bone-esucb.mdx" /* webpackChunkName: "component---2841818740-n-symposium-on-ultrasonic-characterization-of-bone-esucb-mdx" */),
  "component---2891753478-n-symposium-on-ultrasonic-characterization-of-bone-esucb-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/prof-klaus-engelke-presents-qct-methodology-during-the-21st-international-bone-densitometry-workshop-ibdw-and-7th-european-symposium-on-ultrasonic-characterization-of-bone-esucb.mdx" /* webpackChunkName: "component---2891753478-n-symposium-on-ultrasonic-characterization-of-bone-esucb-mdx" */),
  "component---3077974404-cal-size-defect-and-treated-with-and-without-alendronate-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-to-present-the-results-of-a-pre-clinical-study-evaluating-ossure-in-canines-with-a-critical-size-defect-and-treated-with-and-without-alendronate.mdx" /* webpackChunkName: "component---3077974404-cal-size-defect-and-treated-with-and-without-alendronate-mdx" */),
  "component---3868126981-cal-size-defect-and-treated-with-and-without-alendronate-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-to-present-the-results-of-a-pre-clinical-study-evaluating-ossure-in-canines-with-a-critical-size-defect-and-treated-with-and-without-alendronate.mdx" /* webpackChunkName: "component---3868126981-cal-size-defect-and-treated-with-and-without-alendronate-mdx" */),
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-us-jsx": () => import("./../../../src/templates/about-us.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-agnovos-research-jsx": () => import("./../../../src/templates/agnovos-research.jsx" /* webpackChunkName: "component---src-templates-agnovos-research-jsx" */),
  "component---src-templates-agnovos-way-jsx": () => import("./../../../src/templates/agnovos-way.jsx" /* webpackChunkName: "component---src-templates-agnovos-way-jsx" */),
  "component---src-templates-board-jsx": () => import("./../../../src/templates/board.jsx" /* webpackChunkName: "component---src-templates-board-jsx" */),
  "component---src-templates-bone-health-jsx": () => import("./../../../src/templates/bone-health.jsx" /* webpackChunkName: "component---src-templates-bone-health-jsx" */),
  "component---src-templates-careers-jsx": () => import("./../../../src/templates/careers.jsx" /* webpackChunkName: "component---src-templates-careers-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-contact-submission-jsx": () => import("./../../../src/templates/contact_submission.jsx" /* webpackChunkName: "component---src-templates-contact-submission-jsx" */),
  "component---src-templates-event-list-jsx": () => import("./../../../src/templates/event-list.jsx" /* webpackChunkName: "component---src-templates-event-list-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-form-submission-jsx": () => import("./../../../src/templates/form_submission.jsx" /* webpackChunkName: "component---src-templates-form-submission-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-landing-jsx": () => import("./../../../src/templates/landing.jsx" /* webpackChunkName: "component---src-templates-landing-jsx" */),
  "component---src-templates-leadership-jsx": () => import("./../../../src/templates/leadership.jsx" /* webpackChunkName: "component---src-templates-leadership-jsx" */),
  "component---src-templates-news-events-jsx": () => import("./../../../src/templates/news-events.jsx" /* webpackChunkName: "component---src-templates-news-events-jsx" */),
  "component---src-templates-news-events-year-jsx": () => import("./../../../src/templates/news-events-year.jsx" /* webpackChunkName: "component---src-templates-news-events-year-jsx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zurich-switzerland-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zürich-switzerland.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zurich-switzerland-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-dkou-congress-2018-23rd-26th-october-in-berlin-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-at-dkou-congress-2018-23rd-–-26th-october-in-berlin.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-dkou-congress-2018-23rd-26th-october-in-berlin-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-5th-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-–-5th-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-5th-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-23rd-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-–-23rd.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-23rd-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-asbmr-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-asbmr-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-asbmr-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-belgian-bone-club-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-belgian-bone-club-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-belgian-bone-club-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-european-hip-society-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-european-hip-society-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-european-hip-society-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-fragility-fracture-network-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-fragility-fracture-network-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-fragility-fracture-network-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-german-osteology-congress-dvo-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-german-osteology-congress-dvo.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-german-osteology-congress-dvo-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-will-attend-world-congress-on-osteoporosis-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-will-attend-world-congress-on-osteoporosis.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-will-attend-world-congress-on-osteoporosis-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-esceo-agnovos-young-investigator-award-reception-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/esceo-–-agnovos-young-investigator-award-reception.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-esceo-agnovos-young-investigator-award-reception-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-de-world-osteoporosis-day-2021-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/de/world-osteoporosis-day-2021.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-de-world-osteoporosis-day-2021-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zurich-switzerland-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zürich-switzerland.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zurich-switzerland-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-dkou-congress-2018-23rd-26th-october-in-berlin-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-at-dkou-congress-2018-23rd-–-26th-october-in-berlin.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-dkou-congress-2018-23rd-26th-october-in-berlin-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-5th-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-–-5th-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-5th-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-23rd-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-–-23rd.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-23rd-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-asbmr-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-asbmr-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-asbmr-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-belgian-bone-club-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-belgian-bone-club-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-belgian-bone-club-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-european-hip-society-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-european-hip-society-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-european-hip-society-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-fragility-fracture-network-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-fragility-fracture-network-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-fragility-fracture-network-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-german-osteology-congress-dvo-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-german-osteology-congress-dvo.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-german-osteology-congress-dvo-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-will-attend-world-congress-on-osteoporosis-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-will-attend-world-congress-on-osteoporosis.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-will-attend-world-congress-on-osteoporosis-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-esceo-agnovos-young-investigator-award-reception-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/esceo-–-agnovos-young-investigator-award-reception.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-esceo-agnovos-young-investigator-award-reception-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-en-world-osteoporosis-day-2021-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/en/world-osteoporosis-day-2021.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-en-world-osteoporosis-day-2021-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-and-esceo-support-young-investigators-at-wco-in-paris-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-5th-alterstraumatologie-kongress-munich-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zurich-switzerland-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zürich-switzerland.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-alterstraumatologie-congress-2018-22-23-march-zurich-switzerland-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-dkou-congress-2018-23rd-26th-october-in-berlin-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-at-dkou-congress-2018-23rd-–-26th-october-in-berlin.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-dkou-congress-2018-23rd-26th-october-in-berlin-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-dkou-in-berlin-from-october-22nd-25th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-osteologie-kongress-salzburg-in-march-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-5th-2020-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-–-5th-2020.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-wco-in-barcelona-from-april-2nd-5th-2020-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-at-wco-in-paris-from-april-4th-7th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-organized-a-lunch-symposium-at-dkou-2018-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-23rd-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-–-23rd.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-participates-at-virtual-congress-digital-o-u-2020-on-october-19th-23rd-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-co-sponsor-esceo-agnovos-young-investigator-awards-at-world-congress-on-osteoporosis-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-american-society-of-spine-radiology-symposium-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-asbmr-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-asbmr-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-asbmr-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-belgian-bone-club-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-belgian-bone-club-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-belgian-bone-club-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-british-orthopaedic-association-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-european-hip-society-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-european-hip-society-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-european-hip-society-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-fragility-fracture-network-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-fragility-fracture-network-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-fragility-fracture-network-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-german-bones-muscles-and-joints-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-german-congress-of-orthopedics-and-traumatology-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-german-osteology-congress-dvo-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-german-osteology-congress-dvo.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-german-osteology-congress-dvo-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-italian-society-of-orthopedics-medicine-and-rare-diseases-of-the-skeleton-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-swiss-association-against-osteoporosis-svgo-asco-continuing-education-day-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-swiss-orthopaedics-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-2022-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-participate-in-world-congress-on-osteoporosis-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-to-sponsor-webinar-hosted-by-international-osteoporosis-foundation-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-agnovos-healthcare-will-participate-in-the-osteologie-kongress-in-frankfurt-from-march-28th-30th-2019-presenting-scientific-lectures-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-esceo-agnovos-healthcare-young-investigator-awards-presented-at-2019-wco-iof-esceo-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-esceo-agnovos-young-investigator-award-reception-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/esceo-–-agnovos-young-investigator-award-reception.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-esceo-agnovos-young-investigator-award-reception-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-events-fr-world-osteoporosis-day-2021-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/events/fr/world-osteoporosis-day-2021.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-events-fr-world-osteoporosis-day-2021-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-2021-esceo-agnovos-young-investigator-award-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/2021-esceo-agnovos-young-investigator-award.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-2021-esceo-agnovos-young-investigator-award-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-2022-esceo-agnovos-young-investigator-award-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/2022-esceo-agnovos-young-investigator-award.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-2022-esceo-agnovos-young-investigator-award-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agn-1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agn1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agn-1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-bioscience-announces-150th-patient-enrolled-in-restore-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-bioscience-announces-150th-patient-enrolled-in-restore.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-bioscience-announces-150th-patient-enrolled-in-restore-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-completes-enrollment-for-confirm-clinical-study-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-completes-enrollment-for-confirm-clinical-study.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-completes-enrollment-for-confirm-clinical-study-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-receives-breakthrough-designation-for-spine-device-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-receives-breakthrough-designation-for-spine-device.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-receives-breakthrough-designation-for-spine-device-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-supports-the-founding-of-ects-academy-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-supports-the-founding-of-ects-academy.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-supports-the-founding-of-ects-academy-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-to-sponsor-symposium-at-dkou-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-healthcare-to-sponsor-symposium-at-dkou-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-healthcare-to-sponsor-symposium-at-dkou-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-confirm-clinical-study-commences-enrollment-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/confirm-clinical-study-commences-enrollment.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-confirm-clinical-study-commences-enrollment-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-corporate-rebranding-announcement-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/corporate-rebranding-announcement.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-corporate-rebranding-announcement-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-loep-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/loep®-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-loep-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-new-manuscript-on-using-agn-1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/new-manuscript-on-using-agn1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-new-manuscript-on-using-agn-1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-muller-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-müller.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-muller-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-strong-clinical-study-commences-enrollment-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/strong-clinical-study-commences-enrollment.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-strong-clinical-study-commences-enrollment-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-de-strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/de/strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-de-strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-2021-esceo-agnovos-young-investigator-award-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/2021-esceo-agnovos-young-investigator-award.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-2021-esceo-agnovos-young-investigator-award-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-2022-esceo-agnovos-young-investigator-award-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/2022-esceo-agnovos-young-investigator-award.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-2022-esceo-agnovos-young-investigator-award-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agn-1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agn1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agn-1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-bioscience-announces-150th-patient-enrolled-in-restore-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-bioscience-announces-150th-patient-enrolled-in-restore.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-bioscience-announces-150th-patient-enrolled-in-restore-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-completes-enrollment-for-confirm-clinical-study-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-completes-enrollment-for-confirm-clinical-study.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-completes-enrollment-for-confirm-clinical-study-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-receives-breakthrough-designation-for-spine-device-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-receives-breakthrough-designation-for-spine-device.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-receives-breakthrough-designation-for-spine-device-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-supports-the-founding-of-ects-academy-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-supports-the-founding-of-ects-academy.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-supports-the-founding-of-ects-academy-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-to-sponsor-symposium-at-dkou-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-healthcare-to-sponsor-symposium-at-dkou-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-healthcare-to-sponsor-symposium-at-dkou-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-confirm-clinical-study-commences-enrollment-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/confirm-clinical-study-commences-enrollment.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-confirm-clinical-study-commences-enrollment-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-corporate-rebranding-announcement-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/corporate-rebranding-announcement.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-corporate-rebranding-announcement-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-loep-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/loep®-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-loep-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-new-manuscript-on-using-agn-1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/new-manuscript-on-using-agn1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-new-manuscript-on-using-agn-1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-muller-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-müller.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-muller-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-strong-clinical-study-commences-enrollment-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/strong-clinical-study-commences-enrollment.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-strong-clinical-study-commences-enrollment-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-en-strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/en/strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-en-strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-2021-esceo-agnovos-young-investigator-award-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/2021-esceo-agnovos-young-investigator-award.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-2021-esceo-agnovos-young-investigator-award-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-2022-esceo-agnovos-young-investigator-award-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/2022-esceo-agnovos-young-investigator-award.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-2022-esceo-agnovos-young-investigator-award-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agn-1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agn1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agn-1-loep-sv-kit-receives-an-investigational-device-exemption-ide-from-the-u-s-fda-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-bioscience-announces-150th-patient-enrolled-in-restore-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-bioscience-announces-150th-patient-enrolled-in-restore.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-bioscience-announces-150th-patient-enrolled-in-restore-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-again-helps-esceo-to-support-young-investigators-at-wco-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-completes-enrollment-for-confirm-clinical-study-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-completes-enrollment-for-confirm-clinical-study.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-completes-enrollment-for-confirm-clinical-study-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-enters-exclusive-option-agreement-with-asahi-kasei-pharma-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-has-generated-strong-interest-with-the-breakthrough-designation-by-the-fda-and-was-mentioned-in-reputable-press-portals-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-adam-lowe-as-vice-president-of-operations-and-quality-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-mr-falk-dukatz-as-vice-president-global-sales-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-paul-higham-phd-as-chief-scientific-officer-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-paul-laquerre-as-executive-vice-president-and-chief-financial-officer-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-hires-ron-hill-phd-as-vice-president-of-r-d-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-participated-at-wco-in-paris-from-april-4th-7th-2019-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-receives-breakthrough-designation-for-spine-device-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-receives-breakthrough-designation-for-spine-device.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-receives-breakthrough-designation-for-spine-device-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-receives-ethics-committee-approvals-for-the-restore-study-in-netherlands-and-austria-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-supports-the-founding-of-ects-academy-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-supports-the-founding-of-ects-academy.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-supports-the-founding-of-ects-academy-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-to-participate-in-2022-british-orthopaedic-association-congress-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-to-participate-in-german-society-for-geriatric-traumatology-congress-alterstraumatologie-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-to-present-results-of-the-sideways-fall-study-in-cadaveric-femurs-at-ors-meeting-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-to-sponsor-symposium-at-dkou-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-healthcare-to-sponsor-symposium-at-dkou-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-healthcare-to-sponsor-symposium-at-dkou-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-agnovos-researcher-recognized-at-ors-orthopedic-research-society-meeting-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-confirm-clinical-study-commences-enrollment-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/confirm-clinical-study-commences-enrollment.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-confirm-clinical-study-commences-enrollment-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-corporate-rebranding-announcement-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/corporate-rebranding-announcement.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-corporate-rebranding-announcement-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-dr-james-howe-to-deliver-lecture-on-ossure-loep-procedural-technique-at-dkou-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-dr-james-howe-to-lecture-on-ossure-loep-during-ortomed-conference-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-dr-james-howe-to-present-proof-of-concept-clinical-study-at-the-world-congress-on-osteoporosis-2017-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-loep-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/loep®-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-loep-included-in-2019-iof-and-esceo-european-guidance-for-the-diagnosis-and-management-of-osteoporosis-in-postmenopausal-women-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-new-manuscript-on-using-agn-1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/new-manuscript-on-using-agn1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-new-manuscript-on-using-agn-1-implant-material-to-treat-bone-loss-published-in-peer-reviewed-journal-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-new-pre-clinical-data-on-ossure-loep-published-in-journal-of-orthopedic-research-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-muller-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-müller.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-new-treatment-methods-for-local-osteoporotic-bone-loss-ndr-broadcast-with-dr-muller-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-presentation-of-finite-element-analysis-fea-of-proximal-femurs-of-participants-in-the-copley-clinical-study-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-strong-clinical-study-commences-enrollment-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/strong-clinical-study-commences-enrollment.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-strong-clinical-study-commences-enrollment-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-strong-clinical-study-interim-results-presented-at-alterstraumatologie-congress-2018-mdx" */),
  "component---src-templates-news-item-jsx-content-file-path-content-news-fr-strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference-mdx": () => import("./../../../src/templates/news-item.jsx?__contentFilePath=/opt/build/repo/content/news/fr/strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference.mdx" /* webpackChunkName: "component---src-templates-news-item-jsx-content-file-path-content-news-fr-strong-clinical-study-interim-results-to-be-presented-at-the-asia-pacific-orthopedics-association-conference-mdx" */),
  "component---src-templates-news-list-jsx": () => import("./../../../src/templates/news-list.jsx" /* webpackChunkName: "component---src-templates-news-list-jsx" */),
  "component---src-templates-ossure-jsx": () => import("./../../../src/templates/ossure.jsx" /* webpackChunkName: "component---src-templates-ossure-jsx" */),
  "component---src-templates-site-map-jsx": () => import("./../../../src/templates/site-map.jsx" /* webpackChunkName: "component---src-templates-site-map-jsx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-de-commitment-to-ethics-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/de/commitment-to-ethics.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-de-commitment-to-ethics-mdx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-de-privacy-policy-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/de/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-de-privacy-policy-mdx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-de-terms-of-use-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/de/terms-of-use.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-de-terms-of-use-mdx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-en-commitment-to-ethics-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/en/commitment-to-ethics.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-en-commitment-to-ethics-mdx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-en-privacy-policy-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/en/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-en-privacy-policy-mdx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-en-terms-of-use-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/en/terms-of-use.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-en-terms-of-use-mdx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-fr-commitment-to-ethics-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/fr/commitment-to-ethics.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-fr-commitment-to-ethics-mdx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-fr-privacy-policy-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/fr/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-fr-privacy-policy-mdx" */),
  "component---src-templates-site-page-jsx-content-file-path-content-site-fr-terms-of-use-mdx": () => import("./../../../src/templates/site-page.jsx?__contentFilePath=/opt/build/repo/content/site/fr/terms-of-use.mdx" /* webpackChunkName: "component---src-templates-site-page-jsx-content-file-path-content-site-fr-terms-of-use-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-david-stiller-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/david-stiller.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-david-stiller-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-dawn-svoronos-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/dawn-svoronos.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-dawn-svoronos-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-dr-james-barry-ph-d-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/dr-james-barry-ph-d.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-dr-james-barry-ph-d-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-dr-james-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/dr-james-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-dr-james-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-ellen-zane-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/ellen-zane.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-ellen-zane-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-michael-schmertzler-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/michael-schmertzler.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-michael-schmertzler-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-nicholas-valeriani-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/nicholas-valeriani.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-nicholas-valeriani-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-robert-p-stiller-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/robert-p-stiller.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-robert-p-stiller-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-stephen-macmillan-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/stephen-macmillan.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-stephen-macmillan-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-tanner-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/de/tanner-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-de-tanner-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-david-stiller-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/david-stiller.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-david-stiller-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-dawn-svoronos-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/dawn-svoronos.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-dawn-svoronos-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-dr-james-barry-ph-d-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/dr-james-barry-ph-d.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-dr-james-barry-ph-d-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-dr-james-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/dr-james-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-dr-james-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-ellen-zane-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/ellen-zane.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-ellen-zane-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-michael-schmertzler-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/michael-schmertzler.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-michael-schmertzler-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-nicholas-valeriani-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/nicholas-valeriani.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-nicholas-valeriani-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-robert-p-stiller-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/robert-p-stiller.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-robert-p-stiller-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-stephen-macmillan-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/stephen-macmillan.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-stephen-macmillan-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-tanner-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/en/tanner-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-en-tanner-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-david-stiller-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/david-stiller.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-david-stiller-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-dawn-svoronos-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/dawn-svoronos.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-dawn-svoronos-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-dr-james-barry-ph-d-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/dr-james-barry-ph-d.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-dr-james-barry-ph-d-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-dr-james-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/dr-james-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-dr-james-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-ellen-zane-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/ellen-zane.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-ellen-zane-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-michael-schmertzler-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/michael-schmertzler.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-michael-schmertzler-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-nicholas-valeriani-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/nicholas-valeriani.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-nicholas-valeriani-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-robert-p-stiller-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/robert-p-stiller.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-robert-p-stiller-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-stephen-macmillan-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/stephen-macmillan.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-stephen-macmillan-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-tanner-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/board/fr/tanner-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-board-fr-tanner-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-adam-lowe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/adam-lowe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-adam-lowe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-chris-gegelys-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/chris-gegelys.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-chris-gegelys-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-dr-james-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/dr-james-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-dr-james-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-dr-paul-higham-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/dr-paul-higham.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-dr-paul-higham-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-dr-ronald-hill-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/dr-ronald-hill.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-dr-ronald-hill-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-dr-stephanie-kladakis-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/dr-stephanie-kladakis.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-dr-stephanie-kladakis-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-falk-dukatz-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/falk-dukatz.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-falk-dukatz-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-james-pilachowski-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/james-pilachowski.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-james-pilachowski-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-jarrett-waldner-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/jarrett-waldner.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-jarrett-waldner-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-tanner-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/de/tanner-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-de-tanner-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-adam-lowe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/adam-lowe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-adam-lowe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-chris-gegelys-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/chris-gegelys.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-chris-gegelys-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-dr-james-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/dr-james-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-dr-james-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-dr-paul-higham-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/dr-paul-higham.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-dr-paul-higham-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-dr-ronald-hill-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/dr-ronald-hill.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-dr-ronald-hill-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-dr-stephanie-kladakis-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/dr-stephanie-kladakis.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-dr-stephanie-kladakis-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-falk-dukatz-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/falk-dukatz.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-falk-dukatz-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-james-pilachowski-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/james-pilachowski.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-james-pilachowski-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-jarrett-waldner-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/jarrett-waldner.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-jarrett-waldner-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-tanner-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/en/tanner-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-en-tanner-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-adam-lowe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/adam-lowe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-adam-lowe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-chris-gegelys-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/chris-gegelys.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-chris-gegelys-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-dr-james-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/dr-james-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-dr-james-howe-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-dr-paul-higham-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/dr-paul-higham.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-dr-paul-higham-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-dr-ronald-hill-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/dr-ronald-hill.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-dr-ronald-hill-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-dr-stephanie-kladakis-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/dr-stephanie-kladakis.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-dr-stephanie-kladakis-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-falk-dukatz-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/falk-dukatz.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-falk-dukatz-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-james-pilachowski-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/james-pilachowski.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-james-pilachowski-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-jarrett-waldner-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/jarrett-waldner.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-jarrett-waldner-mdx" */),
  "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-tanner-howe-mdx": () => import("./../../../src/templates/team-member.jsx?__contentFilePath=/opt/build/repo/content/team/leadership/fr/tanner-howe.mdx" /* webpackChunkName: "component---src-templates-team-member-jsx-content-file-path-content-team-leadership-fr-tanner-howe-mdx" */),
  "component---src-templates-virtual-booth-jsx": () => import("./../../../src/templates/virtual-booth.jsx" /* webpackChunkName: "component---src-templates-virtual-booth-jsx" */),
  "slice---src-components-layout-footer-footer-jsx": () => import("./../../../src/components/Layout/Footer/Footer.jsx" /* webpackChunkName: "slice---src-components-layout-footer-footer-jsx" */),
  "slice---src-components-layout-header-header-jsx": () => import("./../../../src/components/Layout/Header/Header.jsx" /* webpackChunkName: "slice---src-components-layout-header-header-jsx" */)
}

