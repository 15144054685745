import { SET_ARTICLE } from "./actions";

export const initialState = {
  id: "",
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ARTICLE:
      return { ...state, ...{ id: payload } };
    default:
      return state;
  }
};
